import { SupportedLocale } from 'i18n';
import useI18nTranslation from 'next-translate/useTranslation';

const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

export const useTranslation = (prefix = '') => {
  const { t: translate, ...i18n } = useI18nTranslation();

  const t = (s: string, forcedPrefix = '', dynamicData: any = {}) => {
    if (typeof s !== 'string') {
      return '';
    }
    if (s.indexOf(' ') >= 0) {
      return s;
    }
    const p = forcedPrefix || prefix;
    return p
      ? translate(`${p.toLowerCase()}.${s}`.replace('.', ':'), dynamicData)
      : translate(s.replace('.', ':'), dynamicData);
  };
  const tu = (s: string, forcedPrefix = '', dynamicData: any = {}) =>
    capitalizeFirstLetter(t(s, forcedPrefix, dynamicData));

  return { t, tu, i18n, language: i18n.lang as SupportedLocale };
};
