import { useCookies } from 'react-cookie';

type Consent = {
  revibeconsent?: {
    fb: boolean;
    ga: boolean;
  };
};

export const useConsent = () => {
  const [cookies, setCookie] = useCookies<'revibeconsent', Consent>();

  const hasSetConsent = !!cookies.revibeconsent || false;

  const hasFBConsent = cookies.revibeconsent?.fb || false;
  const hasGAConsent = cookies.revibeconsent?.ga || false;

  const setConsent = (consent: Consent) => {
    setCookie(
      'revibeconsent',
      { ...consent.revibeconsent },
      {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      }
    );
  };

  return {
    consentCookie: cookies.revibeconsent,
    hasSetConsent,
    hasFBConsent,
    hasGAConsent,
    setConsent,
  };
};
