const COMMON_NAMESPACES = [
  'auth',
  'common',
  'notifications',
  'shop',
  'titles',
  'user',
  'seo',
];
const HOME_NAMESPACES = ['home', 'news'];
const LISTING_NAMESPACES = ['listing'];
const BRANDS_NAMESPACES = ['seller'];
const ITEM_NAMESPACES = ['item'];
const COLLECTION_NAMESPACES = ['collection'];
const BLOG_NAMESPACES = ['blog'];
const NEWS_NAMESPACES = ['news'];

module.exports = {
  defaultLocale: 'en',
  locales: ['en', 'fr', 'it'],
  pages: {
    '*': COMMON_NAMESPACES,
    '/': HOME_NAMESPACES,
    '/shop/items': LISTING_NAMESPACES,
    '/shop/items/[sex]': LISTING_NAMESPACES,
    '/shop/items/[sex]/[category]': LISTING_NAMESPACES,
    '/shop/brands': BRANDS_NAMESPACES,
    '/shop/brands/[slug]': [...BRANDS_NAMESPACES, ...LISTING_NAMESPACES],
    '/shop/item/[slug]': [...ITEM_NAMESPACES, ...COLLECTION_NAMESPACES],
    '/shop/selections/[slug]': [
      ...LISTING_NAMESPACES,
      ...COLLECTION_NAMESPACES,
    ],
    '/shop/collections/[slug]': [
      ...LISTING_NAMESPACES,
      ...COLLECTION_NAMESPACES,
    ],
    '/blog': BLOG_NAMESPACES,
    '/blog/[category]/[slug]': BLOG_NAMESPACES,
    '/blog/[category]': BLOG_NAMESPACES,
    'rgx:/news/*': NEWS_NAMESPACES,
  },
};
