import { createSlice } from '@reduxjs/toolkit';

type SliceType = {
  id: number | null;
};

type IdAction = {
  payload: number | null;
  type: string;
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    id: null,
  } as SliceType,
  reducers: {
    updateCartID: (state, action: IdAction) => {
      state.id = action.payload;
    },
  },
});

export const { updateCartID } = cartSlice.actions;

export const cartReducer = cartSlice.reducer;

export const selectCartID = (state: { cart: SliceType }) => state.cart.id;
