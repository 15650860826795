import React from 'react';

import { I18N_NAMESPACES } from 'i18n';
import { useTranslation } from 'i18n/hooks';
import { Button } from 'revibe-ui';
import { SpacedContainer } from 'revibe-ui';
import { COMPANY_ROUTES } from 'routes';

import NoSSR from 'modules/common/components/NoSSR';
import { useConsent } from 'modules/tracking/hooks/useConsent';

import { Link } from 'ui/components/Link';

export const CookieBanner = () => {
  const { tu } = useTranslation(I18N_NAMESPACES.COMMON);
  const { hasSetConsent, setConsent } = useConsent();

  if (hasSetConsent) {
    return null;
  }

  return (
    <NoSSR>
      <div className="fixed bottom-0 left-0 z-10 m-2 box-border max-w-lg border border-white bg-black p-4 text-white shadow-lg md:bottom-4 md:left-4">
        <SpacedContainer>
          <span className="text-xs">{tu('cookies-message')}</span>
          <SpacedContainer>
            <Button
              variant="outline"
              type="button"
              className="w-full"
              onClick={() => {
                setConsent({
                  revibeconsent: {
                    fb: true,
                    ga: true,
                  },
                });
              }}
            >
              {tu('cookies-cta')}
            </Button>
            <Link to={COMPANY_ROUTES.COOKIE_POLICY} className="text-xs">
              {tu('cookies-customize')}
            </Link>
          </SpacedContainer>
        </SpacedContainer>
      </div>
    </NoSSR>
  );
};
