import { Inter, Syne } from 'next/font/google';
import localFont from 'next/font/local';
import { cn } from 'revibe-ui';

const formula = localFont({
  src: './FormulaCondensed-Light.woff2',
  variable: '--font-formula',
});

const inter = Inter({ subsets: ['latin'], variable: '--font-inter' });

const syne = Syne({
  subsets: ['latin'],
  variable: '--font-syne',
});

export const fontVariables = cn(
  inter.variable,
  syne.variable,
  formula.variable,
  'font-sans'
);
