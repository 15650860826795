import { Carousel } from 'flowbite-react';

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';

import { Image, ImageProps } from './Image';

export interface Props {
  images: ImageProps[];
}

export const ImageCarousel = ({ images }: Props) => {
  return (
    <Carousel
      slide={false}
      rightControl={
        images.length > 1 ? (
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-full bg-white p-1 shadow-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
          >
            <ArrowRightIcon className="h-4 w-4 text-gray-500 hover:text-gray-700" />
          </button>
        ) : (
          <div className="hidden" />
        )
      }
      leftControl={
        images.length > 1 ? (
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-full bg-white p-1 shadow-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
          >
            <ArrowLeftIcon className="h-4 w-4 text-gray-500 hover:text-gray-700" />
          </button>
        ) : (
          <div className="hidden" />
        )
      }
    >
      {images.map((image) => (
        <Image key={image.src} {...image} />
      ))}
    </Carousel>
  );
};
