// TO NOT INDEX
export const AUTH_ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  UNAUTHORIZED: '/unauthorized',
  RESET_PASSWORD: '/reset-password',
};
export const USER_ROUTES = {
  ACCOUNT: '/account',
  ORDERS: '/account/orders',
  WISHLIST: '/account/wishlist',
  WALLET: '/account/wallet',
};
export const CHECKOUT_ROUTES = {
  CART: '/checkout/cart',
  DELIVERY_CONFIGURATION: '/checkout/delivery-configuration',
  PAYMENT: '/checkout/payment',
  SUCCESS: '/checkout/success',
};
export const TEMP_ROUTES = {
  FALL_PROMO: '/fall-promo',
};
export const BLOG_ROUTES = {
  HOME: '/blog',
  CATEGORY: '/blog/:category',
  ARTICLE: '/blog/:category/:slug',
};
export const NEWS_ROUTES = {
  HOME: '/news',
  ARTICLE: '/news/:slug',
};

// TO INDEX FOR SEO
export const COMPANY_ROUTES = {
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  DELIVERY_AND_RETURNS: '/delivery-and-returns',
  PAYMENTS: '/payments',
  COOKIE_POLICY: '/cookie-policy',
  PLATFORM: '/platform',
};
export const SHOP_ROUTES = {
  ITEM: '/shop/item',
  ITEM_SLUG: '/shop/item/:slug',
  CATEGORIES: '/shop/categories',
  CATEGORIES_SEX: '/shop/categories/:sex',
  ITEMS: '/shop/items',
  ITEMS_SEX: '/shop/items/:sex',
  ITEMS_SEX_CATEGORY: '/shop/items/:sex/:category',
  SELLERS: '/shop/brands',
  SELLER_SLUG: '/shop/brands/:slug',
  COLLECTION: '/shop/collections/:slug',
  SELECTIONS: '/shop/selections',
  SELECTION: '/shop/selections/:slug',
};
export const SELLER_ROUTES = {
  SELLER_ONBOARDING_REFRESH: '/sellers/onboarding/refresh',
};
