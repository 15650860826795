import { forwardRef } from 'react';

import { format } from 'date-fns';
import DatePickerLib from 'react-datepicker';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { cn } from '../../../helpers/classnames';

// import enLocale from 'date-fns/locale/en-GB';
// import frLocale from 'date-fns/locale/fr';
// import itLocale from 'date-fns/locale/it';

const ButtonInput = forwardRef(function ButtonInput(
  {
    onClick,
    date,
    showTimeSelect,
  }: {
    date: Date;
    language?: string;
    showTimeSelect?: boolean;
    onClick?: () => void;
  },
  ref: any
) {
  // const locale =
  //   language === 'fr' ? frLocale : language === 'it' ? itLocale : enLocale;

  return (
    <button
      onClick={onClick}
      ref={ref}
      type="button"
      className="focus:ring-purple inline-flex w-full justify-start whitespace-nowrap rounded border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0"
    >
      {format(
        new Date(date),
        showTimeSelect ? 'dd MMMM yyyy - HH:mm' : 'dd MMMM yyyy'
        // { locale: locale }
      )}
    </button>
  );
});

interface Props {
  date: Date;
  onChange: (d: Date) => void;
  language?: string;
  showTimeSelect?: boolean;
}

export const ControlledDatePicker = ({
  date,
  onChange,
  language = 'en',
  showTimeSelect = false,
}: Props) => {
  // const locale =
  //   language === 'fr' ? frLocale : language === 'it' ? itLocale : enLocale;

  return (
    <DatePickerLib
      locale={language}
      selected={date}
      onChange={onChange}
      showTimeSelect={showTimeSelect}
      popperClassName="react-datepicker-left"
      nextMonthButtonLabel=">"
      previousMonthButtonLabel="<"
      customInput={
        <ButtonInput
          date={date}
          language={language}
          showTimeSelect={showTimeSelect}
        />
      }
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex items-center justify-between px-2 py-2">
          <span className="text-lg text-gray-700">
            {format(date, 'MMMM yyyy')}
          </span>

          <div className="space-x-2">
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className={cn(
                prevMonthButtonDisabled && 'cursor-not-allowed opacity-50',
                'inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0'
              )}
            >
              <ChevronLeftIcon className="h-5 w-5 text-gray-600" />
            </button>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className={cn(
                nextMonthButtonDisabled && 'cursor-not-allowed opacity-50',
                'inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-50 focus:ring-offset-0'
              )}
            >
              <ChevronRightIcon className="h-5 w-5 text-gray-600" />
            </button>
          </div>
        </div>
      )}
    />
  );
};
