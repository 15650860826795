import { configureStore } from '@reduxjs/toolkit';

import { cartReducer } from './features/cart';
import { modalsReducer } from './features/modals';
import { loadState, saveState } from './localStorage';

const persistedState = loadState();
export const store = configureStore({
  reducer: {
    cart: cartReducer,
    modals: modalsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState({
    cart: store.getState().cart,
  });
});
