import { createSlice } from '@reduxjs/toolkit';

type SliceType = {
  cart: boolean;
  auth: boolean;
};

type ToggleAction = {
  payload: boolean;
  type: string;
};

const INITIAL_STATE: SliceType = {
  cart: false,
  auth: false,
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: INITIAL_STATE,
  reducers: {
    toggleCartPanel: (state, action: ToggleAction) => {
      state.cart = action.payload;
    },
    toggleAuthModal: (state, action: ToggleAction) => {
      state.auth = action.payload;
    },
  },
});

export const { toggleAuthModal, toggleCartPanel } = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;

export const selectCartPanelIsOpen = (state: { modals: SliceType }) =>
  state.modals.cart;

export const selectAuthModalIsOpen = (state: { modals: SliceType }) =>
  state.modals.auth;
