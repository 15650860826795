import React from 'react';

import NextLink from 'next/link';

import { cn } from 'revibe-ui';

type Props = {
  to: string;
  hasDecoration?: boolean;
  children: React.ReactNode;
  target?: string;
  className?: string;
  type?: 'standard' | 'bold';
};

export const Link = ({
  to,
  hasDecoration = true,
  children,
  target,
  className,
  type = 'standard',
}: Props) => {
  if (target === '_blank') {
    return (
      <a
        rel="noopener noreferrer"
        target={target}
        href={to}
        className={cn(
          'cursor-pointer',
          type === 'bold' && 'font-semibold',
          hasDecoration && 'hover:underline',
          className
        )}
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink
      href={to}
      className={cn(
        type === 'bold' && 'font-semibold',
        hasDecoration && 'hover:underline',
        className
      )}
    >
      {children}
    </NextLink>
  );
};
