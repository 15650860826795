import { isBrowserEnv } from 'modules/common/utils/env';

export const loadState = () => {
  if (!isBrowserEnv()) {
    return undefined;
  }
  try {
    if (isBrowserEnv()) {
      const serializedState = window.localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  if (!isBrowserEnv()) {
    return;
  }
  try {
    const serializedState = JSON.stringify(state);
    if (isBrowserEnv()) {
      window.localStorage.setItem('state', serializedState);
    }
  } catch {
    return;
  }
};
