export const I18N_NAMESPACES = {
  AUTH: 'auth',
  ERROR: 'error',
  COMMON: 'common',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_INFO: 'notifications.info',
  NOTIFICATIONS_ERROR: 'notifications.error',
  PAGE_TITLES: 'titles',
  SELLER: 'seller',
  SHOP: 'shop',
  USER: 'user',
  SEO: 'seo',
  HOME: 'home',
  LISTING: 'listing',
  ITEM: 'item',
  COLLECTION: 'collection',
  BLOG: 'blog',
  NEWS: 'news',
};

export type SupportedLocale = 'en' | 'fr' | 'it';

export const SUPPORTED_LOCALES: { [key: string]: SupportedLocale } = {
  EN: 'en',
  FR: 'fr',
  IT: 'it',
};

export const SUPPORTED_LOCALES_STRINGS: SupportedLocale[] = ['en', 'fr', 'it'];
