import { createMedia } from '@artsy/fresnel';

export const BREAKPOINTS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    [BREAKPOINTS.MOBILE]: 0,
    [BREAKPOINTS.DESKTOP]: 1024,
  },
});

export { MediaContextProvider, Media };
